import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';

import SEO from '../../components/seo';
import ConfiguratorHome from '../../screens/configurator';

import trads from '../../screens/configurator/trads';
import { getBrands } from './utils';
import { Product } from '../../utils';

const ConfiguratorHomeScreen = (props) => {
  const { data, location, pageContext } = props;
  const intl = useIntl();
  const brands = getBrands(data.brands.nodes);

  const products = (data?.products?.nodes || []).map(p => Product(p, intl.locale));
  console.log('data', data);

  const ranges = data.instore.data.productranges.map(r => {
    const data = r.category.document.data;
    return {
      ...data,
      products: data.products.map(p => {
        const product = p.product.document.data;
        const current = products.find(p => p.shopifyAdminId === product.shopify_product?.admin_graphql_api_id);

        return {
          ...product,
          shopify_product: undefined,
          product: current
        };
      }),
    };
  });

  console.log('ranges', ranges);

  const title = intl.formatMessage(trads.configuratorTitle, {
    lineBreak: <br />,
  });

  console.log('Config home', pageContext);

  return (
    <>
      <SEO
        title={intl.formatMessage(trads.configuratorTitle, {
          lineBreak: '',
        })}
      />
      <ConfiguratorHome
        brands={brands}
        location={location}
        title={title}
        rootPath={pageContext.rootPath}
        ranges={ranges}
      />
    </>
  );
}

export const query = graphql`
  query ConfiguratorHome(
    $locale: String!,
    $fetchProducts: [String!]
  ) {
    brands: allConfiguratorBrandsJson {
      nodes {
        id
        name
      }
    }
    products: allShopifyProduct(
      filter: {
        shopifyId: { in: $fetchProducts },
        locale: { eq: $locale }
      }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        customfields {
          features {
            icon {
              cloudinary_src
              aspect_ratio
              alt
            }
            text
          }
        }
      }
    }
    instore: prismicInstore {
      data {
        productranges {
          category {
            document {
              ... on PrismicProductrangeCategory {
                id
                data {
                  title
                  products {
                    product {
                      document {
                        ... on PrismicProductrange {
                          data {
                            title
                            product_packshot {
                              alt
                              dimensions {
                                height
                                width
                              }
                              url
                            }
                            link_name
                            link {
                              url
                              target
                            }
                            description
                            buy_link_name
                            buy_link {
                              url
                              target
                            }
                            viscosity {
                              html
                            }
                            cover {
                              alt
                              dimensions {
                                height
                                width
                              }
                              url
                            }
                            shopify_product
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ConfiguratorHomeScreen;
